<template>
  <viewer>
    <img :src="imgUrl" class="image"/>
  </viewer>
</template>

<script>

export default {
  name: "ImageViewer",
  props: ['imgUrl'],
};
</script>

<style scoped>
.image {
  max-width: 80%;
  height: 400px;
}
</style>
