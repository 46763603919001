<template>
  <div class="pdf">
    <div class="pdf__nav">
      <input class="pdf__nav__input" v-model.number="page" type="number" />
      of {{ numPages }}
    </div>
    <div>
      <div
        v-if="loadedRatio > 0 && loadedRatio < 1"
        style="background-color: green; color: white; text-align: center"
        :style="{ width: loadedRatio * 100 + '%' }"
      >
        {{ Math.floor(loadedRatio * 100) }}%
      </div>
      <pdf
        ref="pdf"
        class="pdf__body"
        :src="pdfUrl"
        :page="page"
        @progress="loadedRatio = $event"
        @error="error"
        @num-pages="numPages = $event"
        @link-clicked="page = $event"
      ></pdf>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  name: "PdfViewer",
  props: ["pdfUrl"],
  components: {
    pdf,
  },
  data() {
    return {
      src: "",
      loadedRatio: 0,
      page: 1,
      numPages: 0,
    };
  },
  methods: {
    error(err) {
      console.log(err);
    },
  },
};
</script>

<style scoped lang="scss">
.pdf {
  background-color: #d9d9d9;
  width: 95%;
  margin: 1rem ;
  &__nav {
    background-color: #6c757d;
    color: white;
    padding: 0.5rem 0;
    &__input {
      width: 5em;
      background-color: #3f4b5b;
      color: white;
    }
  }
  &__body {
    width: 90%;
    height: 80vh;
    margin: auto;
    overflow-y: scroll;
  }
}
</style>
