<template>
  <div class="home">
    <div class="inputs-container">
      <div class="inputs-container__input">
        <label class="inputs-container__label" for="url">Url:</label>
        <InputText class="p-inputtext-sm" id="url" type="text" v-model="urlBack" />
      </div>
      <div class="inputs-container__input">
        <label class="inputs-container__label" for="token">Token:</label>
        <InputText class="p-inputtext-sm" id="token" type="text" v-model="token" />
      </div>
    </div>
    <div class="wrapper">
      <div class="left">
        <div class="left__upload-file">
          <el-upload
            class="upload"
            :on-preview="handlePreview"
            :on-change="handleChange"
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple
            list-type="picture"
            :auto-upload="false"
            :show-file-list="false"
          >
            <el-button class="upload__button" slot="trigger" size="small" type="primary"
              >Selecciona un archivo</el-button
            >
            <div slot="tip" class="el-upload__tip">Solo archivos .pdf, .png o .jpg</div>
          </el-upload>
          <div class="content-files">
            <Carousel
              v-if="fileList.length >= 1"
              :value="fileList"
              :numVisible="2"
              :numScroll="2"
              :responsiveOptions="responsiveOptions"
              orientation="vertical"
            >
              <template #item="slotProps">
                <div class="content-files__item">
                  <div class="content-files__item__content" v-tooltip.top="slotProps.data.name">
                    <div class="content-files__item__content__img-container">
                      <img
                        v-if="fileType[slotProps.index] === 'pdf'"
                        class="content-files__item__content__img-container--image"
                        src="../assets/images/pdfLogo.svg"
                      />
                      <img
                        v-else
                        :src="slotProps.data.url"
                        :alt="slotProps.data.name"
                        class="content-files__item__content__img-container--image"
                      />
                    </div>
                    <div>
                      <p class="content-files__item__content--text">
                        {{ shortNames[slotProps.index] }} ...
                      </p>
                      <div class="content-files__item__content--buttons mt-5">
                        <Button
                          icon="pi pi-eye"
                          @click="handlePreview(slotProps.data, fileType[slotProps.index])"
                          class="p-button p-button-rounded mr-2"
                        />
                        <Button
                          icon="pi pi-upload"
                          @click="uploadFile(slotProps.data)"
                          class="p-button-success p-button-rounded mr-2"
                        />
                        <Button
                          icon="pi pi-trash"
                          @click="deleteFile(slotProps.index)"
                          class="p-button-danger p-button-rounded"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Carousel>
          </div>
        </div>
        <div class="left__viewer">
          <div v-if="pdfUrl !== ''">
            <pdf-viewer :pdfUrl="pdfUrl" />
          </div>
          <div v-if="imgUrl !== ''">
            <image-viewer :imgUrl="imgUrl" />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="gutter">
          <div class="gutter__block"></div>
        </div>
        <ProgressSpinner v-if="loading === true"/>
        <json-viewer v-else :value="json" :expand-depth="5" copyable sort></json-viewer>
      </div>
    </div>
    <div class="right__company">
      <p><b>Powered by</b></p>
      <img class="right__company__logo" src="../assets/images/hyperLogo.png" />
    </div>
  </div>
</template>

<script>
import ImageViewer from "../components/ImageViewer.vue";
import PdfViewer from "../components/PdfViewer.vue";

export default {
  name: "Home",
  components: {
    ImageViewer,
    PdfViewer,
  },
  data() {
    return {
      fileList: [],
      fileType: [],
      shortNames: [],
      json: {},
      pdfUrl: "",
      imgUrl: "",
      urlBack: "",
      token: "",
      loading: false,
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  mounted() {
    this.handleMyProcessesSplitter();
  },
  methods: {
    async uploadFile(file) {
      if (this.urlBack === "") {
        this.$toast.add({
          severity: "error",
          summary: "URL is missing",
          detail: "Before upload, please enter the url in the input",
          life: 4000,
        });
      } else {
        this.loading = true;
        const payload = {};
        payload.file = file.raw;
        payload.url = this.urlBack;
        payload.token = this.token;
        const response = await this.$store.dispatch("user/uploadFile", payload);
        if (response.status !== 200) {
          this.$toast.add({
            severity: "error",
            summary: response,
            detail: "Error uploadind the file",
            life: 4000,
          });
        } else {
          this.json = response.data;
          this.$toast.add({
            severity: "success",
            summary: "Success Upload",
            detail: "The file was upload correctly",
            life: 4000,
          });
        }
        this.loading = false;
      }
    },
    deleteFile(index) {
      if (this.fileList[index].url === this.pdfUrl) {
        this.pdfUrl = "";
      } else if (this.fileList[index].url === this.imgUrl) {
        this.imgUrl = "";
      }
      this.fileList.splice(index, 1);
      this.fileType.splice(index, 1);
      this.shortNames.splice(index, 1);
    },
    handlePreview(file, type) {
      if (type === "pdf") {
        this.imgUrl = "";
        this.pdfUrl = file.url;
      } else {
        this.pdfUrl = "";
        this.imgUrl = file.url;
      }
    },
    handleChange(file, filist) {
      this.fileList = filist;
      const aux = file.name.split(".");
      this.fileType.push(aux[aux.length - 1]);
      this.shortNames.push(file.name.slice(0, 13));
    },
    handleMyProcessesSplitter() {
      const leftPane = document.querySelector(".left");
      const rightPane = document.querySelector(".right");
      const gutter = document.querySelector(".gutter");
      let prevX = null;
      let leftPanel = null;
      let rightPanel = null;
      function mousemove(e) {
        const newX = prevX - e.x;
        rightPane.style.width = `${rightPanel.width + newX} px`;
        leftPane.style.width = `${leftPanel.width - newX} px`;
      }
      function mouseup() {
        window.removeEventListener("mousemove", mousemove);
        window.removeEventListener("mouseup", mouseup);
      }
      function resizer(e) {
        window.addEventListener("mousemove", mousemove);
        window.addEventListener("mouseup", mouseup);
        prevX = e.x;
        leftPanel = leftPane.getBoundingClientRect();
        rightPanel = rightPane.getBoundingClientRect();
      }
      gutter.addEventListener("mousedown", resizer);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  min-height: 97vh;
}
.inputs-container {
  display: flex;
  justify-content: center;
  width: 100%;
  &__input {
    display: flex;
    margin: 1rem;
    width: 100%;
    align-items: center;
  }
  &__label {
    margin-right: 1rem;
  }
}
.upload {
  display: flex;
  flex-direction: column;
}
.wrapper {
  width: 100%;
  display: flex;
}
.gutter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.5px;
  height: 100%;
  background: #f6f6f6;
  position: absolute;
  top: 0;
  left: 0;
  cursor: col-resize;
  z-index: 1;
}
.gutter__block {
  width: 70%;
  height: 11px;
  background-color: #707070;
  border-radius: 8px;
}
.pane {
  color: black;
  min-width: 200px;
}
.left {
  width: 75%;
  min-width: 30%;
  display: flex;
  &__upload-file {
    width: 20%;
  }
  &__viewer {
    width: 80%;
  }
}
.right {
  width: 25%;
  min-width: 25%;
  position: relative;
  &__company {
    display: inline-flex;
    position: absolute;
    bottom: 0;
    right: 30px;
    &__logo {
      margin-left: 1rem;
      height: 110px;
    }
  }
}
@media (max-width: 800px) {
  .wrapper {
    width: 100%;
    flex-direction: column;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
  }
  .gutter {
    display: none;
  }
}
.content-files {
  &__item {
    &__content {
      margin: 0.3rem;
      text-align: center;
      padding: 0.4rem;
      &__img-container {
        &--image {
          height: 40px;
        }
      }
      &--text {
        font-size: 9px;
        height: auto;
        word-wrap: break-word;
      }
      &--buttons {
        margin: 0.5rem;
      }
    }
  }
}
::v-deep .jv-node {
  text-align: initial;
}
.p-inputtext-sm {
  width: 100%;
}
.p-button-rounded {
  margin-right: 0.2rem;
  height: 1.5rem !important;
  width: 1.5rem;
}
::v-deep .pi {
  font-size: 0.7rem;
}
</style>
